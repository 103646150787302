:root {
  --listing-card-redesign-badge-color: var(--color-green-600);
  --listing-card-redesign-width: 100%;

  --listing-card-redesign-carousel-height: 240px;

  @media (--screen-md) {
    --listing-card-redesign-carousel-height: 100%;
  }
}
