:root {
  --icon-cta-border-radius: var(--border-radius-full);
  --icon-cta-active-color: var(--color-gray-500);
  --icon-cta-hover-color: var(--color-gray-500);
  --icon-cta-base-color: var(--color-gray-500);
  --icon-cta-filled-color: var(--color-red-500);
  --icon-cta-background-color: var(--color-white);
  --icon-cta-hover-background-color: var(--color-white);
  --icon-cta-width: var(--space-10x);
  --icon-cta-height: var(--space-10x);
  --icon-cta-font-size: var(--font-size-xl);
  --icon-cta-margin: unset;
  --icon-cta-padding: unset;
  --icon-cta-position: unset;
  --icon-cta-z-index: unset;
  --icon-cta-top: unset;
  --icon-cta-right: unset;
}
