:root {
  --review-page-header-background-color: var(--color-gray-100);
  --review-page-header-color: var(--color-black);
  --review-page-review-header-back-icon-color: var(--color-black);
  --review-page-review-header-back-button-background-color: var(
    --color-gray-100
  );
  --review-page-review-header-back-button-background-color-active: var(
    --color-gray-100
  );
  --review-page-success-checkbox-background: var(--color-blue-100);
  --review-page-success-checkbox-color: var(--color-black);
  --review-page-review-header-back-button-hover: var(--color-gray-200);
}
