:root {
  --button-display: inline-flex;
  --button-gap: var(--space-2x);
  --button-direction: row;
  --button-placement: center;
  --button-padding: 0 var(--space-4x);
  --button-margin: 0;
  --button-font-size: 1rem;
  --button-font-weight: var(--font-weight-semibold);
  --button-outlined-border-color: var(--color-red-500);

  /* Pill variables */
  --button-pill-border-color: var(--color-blue-600);
  --button-pill-color-base: var(--color-blue-600);
  --button-pill-color-hover: var(--color-white);
  --button-pill-background-hover: var(--color-blue-600);

  --button-background-color-base: var(--color-red-500);
  --button-background-color-hover: var(--color-red-600);
  --button-background-color-active: var(--color-red-400);
  --button-outlined-color-hover: var(--color-red-600);
  --button-outlined-background-color: var(--color-white);
  --button-outlined-base-color: var(--color-red-500);
  --button-outlined-background-color-hover: transparent;
  --button-outlined-font-weight: var(--font-weight-semibold);
  --button-primary-disabled-color: var(--color-gray-400);
  --button-primary-disabled-background-color: var(--color-gray-100);
  --button-text-color-base: currentcolor;
  --button-text-color-hover: currentcolor;
  --button-min-width: inherit;
  --button-width: auto;
  --button-height: 40px;
  --button-height-sm: 30px;
  --button-height-md: 40px;
  --button-height-lg: 46px;
  --button-filter-color: var(--color-gray-500);
  --button-filter-color-hover: var(--color-gray-500);
  --button-filter-border: 1px solid var(--color-gray-200);
  --button-filter-border-bottom: 1px solid var(--color-gray-200);
  --button-rounded-border-radius: var(--space-1x);
  --button-radius-top: 0;
  --button-radius-bottom: 0;
  --button-white-space: nowrap;
}
